import React from 'react';
import PropTypes from 'prop-types';
import styles from '../MockupPreview.module.scss';

const AdminBtn = (props) => {
    const handleCopy = (e) => {
        navigator.clipboard.writeText(e.currentTarget.value);
    };

    if (props.type === 'copy') {
        return (
            <button
                className={props.isInverse ? styles.adminBtnInverse : styles.adminBtn}
                onClick={handleCopy}
                value={props.id}
            >
                Copy ID
            </button>
        );
    }

    return (
        <a
            href={`https://admin-smartmockups.com/mockups?s=${props.id}`}
            className={props.isInverse ? styles.adminBtnInverse : styles.adminBtn}
            target="_blank"
            rel="noopener noreferrer"
        >
            Edit
        </a>
    );
};

AdminBtn.propTypes = {
    id: PropTypes.string,
    isInverse: PropTypes.bool,
    type: PropTypes.string
};

export default AdminBtn;
