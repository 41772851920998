import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { allowedLivePreviewImages } from '../../../utils/allowedFileTypes';
import useLivePreview from '../../../hooks/useLivePreview';
import styles from './LivePreviewUploadButton.module.scss';
import * as windowActions from '../../../actions/windowActions';
import useActions from '../../../hooks/useActions';
import { removePreview } from '../../../utils/routing/LivePreviewRoutes';
import PropTypes from "prop-types";

const acceptedFormats = allowedLivePreviewImages.map((type) => `.${type}`).join(',');

const LivePreviewUploadButtonCore = (props) => {
    const { isClientSide, isPredefined, scrollTo } = props;
    const user = useSelector((state) => state.auth.user);
    const router = useRouter();
    const livePreview = useLivePreview(props.trigger);
    const { setPremiumModal } = useActions(windowActions);
    const inputName = `live-preview-upload-${Math.round(Math.random() * 1000)}`;
    const { predefinedFileId } = livePreview;

    const scrollToElement = () => {
        if (scrollTo) {
            scrollTo();
        }
    };

    const onClick = (event) => {
        if (user || isClientSide) {
            event.target.value = null;
        } else {
            event.preventDefault();
            setPremiumModal('signup', 'Header signup modal');
        }
    };

    const handlePredefinedClick = async (event) => {
        event.preventDefault();
        if (router.query.preview) {
            await removePreview(router);
        }

        livePreview.uploadFileClient(`livePreview/${event.currentTarget.value}.png`, event.currentTarget.value);
        scrollToElement();
    };

    const onChange = async (event) => {
        event.preventDefault();
        const fileNames = event.target.files;

        if (router.query.preview) {
            await removePreview(router);
        }

        const files = Array.from(fileNames);

        if (isClientSide) {
            livePreview.uploadFileClient(URL.createObjectURL(files[0]));
        } else {
            livePreview.uploadFile(files[0]);
        }

        scrollToElement();
    };

    const renderButton = () => {
        if(isPredefined) {
            return (
                <button
                    onClick={handlePredefinedClick}
                    value={props.value}
                    type="button"
                    className={`${props.className} ${styles.predefined} ${props.value === predefinedFileId ? styles.predefinedActive : null}`}
                >
                    {props.children}
                </button>
            );
        }

        return (
            <label htmlFor={inputName} className={props.className}>
                {props.children}
                <input
                    id={inputName}
                    type="file"
                    accept={acceptedFormats}
                    onClick={onClick}
                    onChange={onChange}
                    className={styles.uploadInput}
                />
            </label>
        );
    }

    return (
        <>
            {renderButton()}
        </>
    );
};

LivePreviewUploadButtonCore.propTypes = {
    isPredefined: PropTypes.bool,
    isClientSide: PropTypes.bool,
    scrollTo: PropTypes.func
};

export default LivePreviewUploadButtonCore;
