import React, {useEffect, useReducer} from 'react';
import CollectionPreview from "../../List/CollectionPreview/CollectionPreview";
import CollectionModal from "./CollectionModal";
import CollectionCondenseList from "../../List/CollectionPreview/CollectionCondenseList";
import PropTypes from "prop-types";
import useUserCollections from "../../../hooks/useUserCollections";
import ToolBar from "../../List/CollectionList/ToolBar";
import CollectionForm from "./CollectionForm";
import styles from "./CollectionModal.module.scss";
import MockupListSkeleton from "../../List/MockupList/Skeleton";
import {useSelector} from "react-redux";

const CollectionUpdateMockups = (props) => {

    const {
        state,
        userCollections,
        hasLoadedCollections,
        hasCollectionChange,
        hasChangedStatus,
        maxCountMockups,
        maxCountCollections,
        fetchUserCollections,
        handleUpdateMockups,
        handleCollectionCreate,
        handleModalCollectionCreate
    } = useUserCollections(props);

    const {
        isModalOpen,
        isModalClose,
        modalTitle,
        mockupId,
        mockupThumb
    } = props;

    const {user} = useSelector((state) => state.auth);

    const initialState = {
        isFormOpen: '',
        formTitle: ''
    };

    const [stateForm, setStateForm] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    useEffect(() => {
        if (user && userCollections.length === 0 && isModalOpen) {
            fetchUserCollections(user.sharedDataUsername);
        }
    }, [user, isModalOpen]);

    function getModalTitle() {
        if (stateForm.formTitle === '') {
            return modalTitle;
        }

        return stateForm.formTitle;
    }

    const handleFormOpen = (e) => {
        handleModalCollectionCreate(e);
        setStateForm({
           isFormOpen: true,
           formTitle: 'Create new collection'
        });
    }

    const handleFormClose = () => {
        setStateForm({
            isFormOpen: false,
            formTitle: ''
        });
    }

    const renderCreateForm = () => {
        if (stateForm.isFormOpen === true) {
            return (
                <CollectionForm
                    onFormSubmit={handleCollectionCreate}
                    isLoading={hasCollectionChange}
                    success={state.success}
                    isModalClose={handleFormClose}
                />
            );
        }

        return (
            <ToolBar
                onModalCreate={(e) => handleFormOpen(e)}
                collections={userCollections.length}
                maxCountCollections={maxCountCollections}
            />
        );
    }

    const renderCollections = () => {
        return userCollections.map((collection) => {
            return (
                <CollectionPreview
                    key={collection.timestamp}
                    collection={collection}
                    handleUpdateMockups={handleUpdateMockups}
                    mockupId={mockupId}
                    mockupThumb={mockupThumb}
                    isCondense
                    maxCountMockups={maxCountMockups}
                    isLoading={hasChangedStatus}
                />
            );
        });
    };

    return (
        <CollectionModal
            isModalOpen={isModalOpen}
            isModalClose={isModalClose}
            modalTitle={getModalTitle()}
            size="medium"
            mockupId={mockupId}
            mockupThumb={mockupThumb}
        >
            <div className={styles.wrapperForm}>
                {renderCreateForm()}
            </div>

            {!hasLoadedCollections ? (
                <MockupListSkeleton
                    isSimpleList
                    isHalf
                />
            ) : (
                <CollectionCondenseList>{renderCollections()}</CollectionCondenseList>
            )}
        </CollectionModal>
    );
};

CollectionUpdateMockups.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    isModalClose: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    mockupId: PropTypes.string.isRequired,
    mockupThumb: PropTypes.string.isRequired
}

export default CollectionUpdateMockups;
