import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    getImgixParams,
    PREVIEW_HEIGHT,
    PREVIEW_WIDTH,
    SIZES_WITH_SIDEBAR
} from '../helpers';
import { PUBLIC_MOCKUP_IMGIX } from '../../../../utils/variables';
import isLocalisedMockupPreview from "../../../../utils/locale/isLocalisedMockupPreview";
import Imgix from 'react-imgix';
import { Blurhash } from 'react-blurhash';
import styles from '../MockupPreview.module.scss';

const MockupImage = (props) => {
    const { mockup, isPreviewHover, locale, isLazyLoad, isMasonry } = props;

    const mockupPreviews = mockup.previews ? mockup.previews : mockup;

    const imgixParams = getImgixParams(mockup.category);
    const loc = isLocalisedMockupPreview(locale) ? locale : 'en';
    const thumbId = mockupPreviews.thumb || `${mockup.shortId}_pr`;

    const imageUrl = isMasonry
        ? `${PUBLIC_MOCKUP_IMGIX}/${thumbId}_${loc}.jpg`
        : `${PUBLIC_MOCKUP_IMGIX}/${thumbId}_${loc}.jpg?h=${PREVIEW_HEIGHT}&w=${PREVIEW_WIDTH}`;

    const imageHover = isMasonry
        ? `${PUBLIC_MOCKUP_IMGIX}/${mockup.shortId}_pr_hover.jpg`
        : `${PUBLIC_MOCKUP_IMGIX}/${mockup.shortId}_pr_hover.jpg?h=${PREVIEW_HEIGHT}&w=${PREVIEW_WIDTH}`;
    const isHoverImage = mockupPreviews.hover && isPreviewHover;
    const blurhasId = mockupPreviews.blurhash;
    const blurhash = blurhasId && blurhasId.length > 6 ? blurhasId : 'eIP7X,%M~9%M0g_NkWay%2j]^hof58WC=_-:s:bIIpaeR.j[slkCS5';

    return useMemo(() => {
        return (
            <figure title={mockup.title} className={styles.ratioContainer}>
                <Blurhash
                    hash={blurhash}
                    className={`${styles.blurhash} blurhash`}
                />
                <Imgix
                    className={`${styles.image} ${
                        isLazyLoad && `${styles.isLazy} lazyload`
                    }`}
                    src={`${imageUrl}`}
                    sizes={SIZES_WITH_SIDEBAR}
                    attributeConfig={
                        isLazyLoad && {
                            src: 'data-src',
                            srcSet: 'data-srcset',
                            loading: 'lazy'
                        }
                    }
                    htmlAttributes={{
                        alt: mockup.title
                    }}
                    height={PREVIEW_HEIGHT}
                    imgixParams={imgixParams}
                />

                {isHoverImage && (
                    <Imgix
                        className={`${styles.hover} lazyload`}
                        src={`${imageHover}`}
                        sizes={SIZES_WITH_SIDEBAR}
                        attributeConfig={{
                            src: 'data-src',
                            srcSet: 'data-srcset',
                            loading: 'lazy'
                        }}
                        htmlAttributes={{
                            alt: mockup.title
                        }}
                        height={PREVIEW_HEIGHT}
                        imgixParams={imgixParams}
                    />
                )}
            </figure>
        );
    }, [isHoverImage, imageUrl, imgixParams, mockup]);
};
MockupImage.propTypes = {
    isPreviewHover: PropTypes.bool,
    mockup: PropTypes.object.isRequired,
    locale: PropTypes.string,
    isLazyLoad: PropTypes.bool,
    isMasonry: PropTypes.bool
};

export default MockupImage;
