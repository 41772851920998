import React from 'react';
import PropTypes from 'prop-types';
import Icon from "../../Base/Icons/Icon";
import {FormattedMessage} from "react-intl";
import CollectionUpdateMockups from "../../Modals/CollectionsModal/CollectionUpdateMockups";
import styles from "./CollectionButton.module.scss";
import useUserCollections from "../../../hooks/useUserCollections";

const CollectionButton = (props) => {
    const {mockupId, mockupThumb, isIsolated, className} = props;
    const {
        state,
        user,
        collectedMockups,
        handleModalCollectionUpdate,
        handleModalClose
    } = useUserCollections();
    let findMockup;

    if (collectedMockups) {
        findMockup = collectedMockups.split(',').findIndex((shortId) => shortId === mockupId);
    }

    const renderButton = () => {
        if (findMockup >= 0 && user) {
            return (
                <button className={`${styles.collectionButton} ${styles.isActive}`} onClick={handleModalCollectionUpdate} title="Collected">
                    <span>
                        <Icon name="Plus" width={10} height={10} color="white" />
                    </span>
                </button>
            );
        }

        return (
            <button className={`${styles.collectionButton} ${isIsolated ? styles.isolated : null} ${className}`} onClick={handleModalCollectionUpdate}>
                <span className='font-regular'>
                    <Icon name="Plus" width={10} height={10} color="white" />
                    <FormattedMessage defaultMessage="Collect" />
                </span>
            </button>
        );
    }

    return (
        <>
            <CollectionUpdateMockups
                isModalOpen={state.modalOpen}
                isModalClose={handleModalClose}
                modalTitle={state.modalTitle}
                mockupId={mockupId}
                mockupThumb={mockupThumb}
            />
            {renderButton()}
        </>
    );

};

CollectionButton.propTypes = {
    mockupId: PropTypes.string.isRequired,
    mockupThumb: PropTypes.string.isRequired,
    isIsolated: PropTypes.bool,
    className: PropTypes.string
};

export default CollectionButton;
