export const removePreview = (router) => {
    const query = new URLSearchParams(window.location.search);
    query.delete('preview');
    const queryString = query.toString() ? `?${query.toString()}` : '';

    // check location
    const rootPath = router.route.split('/')[1];

    let url = `/${rootPath}/`;
    if (rootPath === 'mockups') {
        url += router.query.slug ?? '';
    }
    if (rootPath === 'user-collection') {
        url += router.query.id ?? '';
    }

    const href = `${router.pathname}${queryString}`;
    const as = `${url}${queryString}`;

    return router.push(href, as, { shallow: true });
};

export default removePreview;
