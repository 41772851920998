import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import styles from './MockupPreview.module.scss';
import MockupPreviewTitle from './parts/MockupPreviewTitle';
import AdminBtn from './parts/AdminBtn';
import PreviewBadges from './parts/PreviewBadges';
import useMockupPreview from './useMockupPreview';
import MockupImage from './parts/MockupImage';
import CollectionButton from './CollectionButton';
import MockupVideo from "./parts/MockupVideo";
import VideoTimer from "../../Base/VideoTimer/VideoTimer";

const MockupPreview = (props) => {
    const preview = useMockupPreview(props);
    const { mockup, isUserPremium, isUserInternal, isUserScheduled } = props;
    const isNotVideo = mockup.type !== "VIDEO";

    function renderBadges() {
        return (
            <div className={styles.videoBadges}>
                <PreviewBadges
                    mockup={mockup}
                    isUserInternal={isUserInternal}
                    isUserPremium={isUserPremium}
                    isUserScheduled={isUserScheduled}
                />
            </div>
        );
    }

    function renderPreviewTitle() {
        return <MockupPreviewTitle mockup={mockup} />;
    }

    function renderAdminBtn() {
        if (!isUserInternal) return null;

        return (
            <div className={styles.adminBtnWrapper}>
                <AdminBtn id={mockup.id} isInverse={mockup.hasBackground} />
                <AdminBtn id={mockup.shortId} isInverse={mockup.hasBackground} type="copy" />
            </div>
        );
    }

    function renderLivePreview() {
        // in case the preview image is preset by ty parent component
        // display just image
        if (preview.dataUrl) {
            return (
                <div
                    className={styles.livePreview}
                    style={{
                        backgroundImage: `url(${preview.dataUrl})`
                    }}
                >
                    {mockup.type === 'VIDEO' && (
                        <VideoTimer type="list"/>
                    )}
                </div>
            );
        }
        return null;
    }

    function renderPreviewMedia() {
        if (isNotVideo) {
            return (
                <MockupImage
                    mockup={props.mockup}
                    isPreviewHover={
                        props.isPreviewHover &&
                        props.isUserLogged &&
                        !props.livePreview &&
                        !props.isLoading
                    }
                    locale={props.locale}
                    livePreview={preview.dataUrl}
                    isLazyLoad={props.isLazyLoad}
                    isMasonry={props.isMasonry}
                />
            );
        }

        return (
            <MockupVideo
                mockup={props.mockup}
                locale={props.locale}
                isLazyLoad={props.isLazyLoad}
                isMasonry={props.isMasonry}
            />
        );
    }

    function renderLoader() {
        if (!preview.isLoading) return null;
        return <div className={styles.loader} />;
    }

    const handleClick = () => {
        if (props.onClick) {
            props.onClick(props.collection);
        }
    };

    function renderPreviewContent() {
        return (
            <Link to={preview.mockupUrl} className={styles.mockupPreview} title={mockup.title}>
                <button onClick={handleClick} title={mockup.title} data-cy={mockup.isPremium && !isUserPremium ? 'mockup locked' : 'mockup unlocked'}>
                    {renderPreviewMedia()}
                    {renderLivePreview()}
                    {renderLoader()}
                    {renderBadges()}
                    {isNotVideo && renderPreviewTitle()}
                </button>
            </Link>
        );
    }

    function renderCollectionButton() {
        return (
            <CollectionButton
                mockupId={mockup.shortId}
                mockupThumb={mockup.previews ? mockup.previews.thumb : mockup.thumb}
                isIsolated={mockup.hasBackground}
                className={styles.collectionBtn}
            />
        );
    }

    return (
        <article className={`${styles.wrapper} ${props.mockup.hasBackground && styles.imageBordered}`} ref={preview.ref}>
            <div className={styles.previewContent} data-cy="mockupPreview">
                {renderPreviewContent()}
            </div>
            {(!props.hasNotCollectButton && isNotVideo) ? renderCollectionButton() : null}
            {renderAdminBtn()}
        </article>
    );
};

MockupPreview.propTypes = {
    isPreviewHover: PropTypes.bool,
    isUserInternal: PropTypes.bool,
    isUserPremium: PropTypes.bool,
    isUserScheduled: PropTypes.bool,
    mockup: PropTypes.object.isRequired,
    locale: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    isUserLogged: PropTypes.bool,
    isLazyLoad: PropTypes.bool,
    isMasonry: PropTypes.bool,
    hasNotCollectButton: PropTypes.bool
};

export default MockupPreview;
