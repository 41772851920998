import { useEffect, useState } from 'react';
import { getLivePreviewApi, getLivePreviewClientSideApi } from '../../../apis/livePreviewApi';
import getURLSearchParams from '../../../utils/getURLSearchParams';
import { liveColorSubcats } from './previewSettings';
import PropTypes from 'prop-types';
import useLivePreview from '../../../hooks/useLivePreview';
import { useInView } from 'react-intersection-observer';

function useMockupPreview(props) {
    const livePreview = useLivePreview();
    const [dataUrl, setDataUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [ongoingRequest, setOngoingRequest] = useState(undefined);

    const { mockup } = props;
    const { fileId, source, isClientSide, canvas } = livePreview;

    const color = getColor();
    const mockupUrl = getMockupUrl();

    const [ref, inView] = useInView({
        rootMargin: '700px',
        triggerOnce: true
    });

    useEffect(() => {
        if (inView && (fileId || canvas && mockup.screens) && mockup) {
            (async function fetchPreview() {
                setIsLoading(true);
                if (ongoingRequest?.cancelled === false) {
                    ongoingRequest.cancel();
                }
                const thisRequest = isClientSide ? await getLivePreviewClientSideApi(mockup, canvas, color) : getLivePreviewApi(mockup.shortId, fileId, color, source);
                const res = await thisRequest;
                // fetching may have been cancelled outside of this fetch function
                if (!thisRequest.cancelled) {
                    setOngoingRequest(undefined);
                    setDataUrl(isClientSide ? res : res.src);
                    setIsLoading(false);
                }
            })();
        } else {
            setDataUrl(null);
        }
    }, [inView, fileId, canvas, mockup, isClientSide]);

    function getColor() {
        if (!mockup.collections) return null;
        const urlParams = getURLSearchParams();
        mockup.collections.map((collection) => {
            if (liveColorSubcats.indexOf(collection) > -1 && urlParams.has('color')) {
                return urlParams.get('color');
            }
            return null;
        });
        return null;
    }

    function getMockupUrl() {
        const id = mockup.shortId ?? mockup.id;
        let mockupUrl = `/mockup/${encodeURIComponent(id)}`;
        if (color) {
            mockupUrl += `&color=${color}`;
        }
        return mockupUrl;
    }

    return { ref, dataUrl, isLoading, mockupUrl };
}

export default useMockupPreview;

useMockupPreview.propTypes = {
    onLockedClick: PropTypes.func
};
