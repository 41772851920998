// list of allowed file formats (extensions) used by file uploader (file picker and drag'n'drop area)
export const allowedImages = ['jpg', 'jpeg', 'bmp', 'pdf',  'png'];
export const allowedLivePreviewImages = ['jpg', 'jpeg', 'bmp', 'png'];
export const allowedVideos = ['mp4', 'avi', 'mov', 'gif'];

export const VALID_IMAGES_MAGIC_BYTES = {
    bmp: [0x42, 0x4D],
    jpeg: [0xFF, 0xD8, 0xFF],
    png: [0x89, 0x50, 0x4E, 0x47],
    pdf: [37, 80, 68, 70, 45],
}