import React from 'react';
import styles from './CollectionCondenseList.module.scss';

const CollectionCondenseList = (props) => (
    <div
        className={styles.wrapper}
    >
        {props.children}
    </div>
);
export default CollectionCondenseList;
